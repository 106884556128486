import { Injectable } from '@angular/core';

import { Component, OnInit } from '@angular/core';
import { MasterService } from '../master.service';
declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  children?: Array<any>;
}


@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  public routes: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'pe-7s-graph', class: '' },
    {
      path: '/bigliettazione',
      title: 'bigliettazione',
      icon:'pe-7s-ticket',
      class: '',
      children: [
        {
          path: '/bigliettazione/report-emessi',
          title: 'Report biglietti emessi',
          icon:'',
          class: '',
        },
        {
          path: '/bigliettazione/lista-passeggeri',
          title: 'Passeggeri',
          icon:'',
          class: '',
        },
        {
          path: '/bigliettazione/validazione-cassa',
          title: 'Validazione cassa',
          icon:'',
          class: '',
        },
        {
          path: '/bigliettazione/report-abbonamenti',
          title: 'Report Abbonamenti',
          icon:'',
          class: '',
        },
        {
          path: '/bigliettazione/abbonamenti',
          title: 'Abbonamenti',
          icon:'',
          class: '',
        },
        {
          path: '/bigliettazione/operatori',
          title: 'Operatori',
          icon:'',
          class: '',
        },
        {
          path: '/bigliettazione/autisti',
          title: 'Autisti',
          icon:'',
          class: '',
        }
      ]
    },
    {
      path: '/gestione-linee',
      title: 'linee',
      icon:'pe-7s-graph1',
      class: '',
      children: [
        {
          path: '/gestione-linee/linee',
          title: 'Linee',
          icon:'',
          class: '',
        },
        {
          path: '/gestione-linee/fermate',
          title: 'Fermate',
          icon:'',
          class: '',
        },
        {
          path: '/gestione-linee/percorsi',
          title: 'Percorsi',
          icon:'',
          class: '',
        },
        {
          path: '/gestione-linee/corse',
          title: 'Corse',
          icon:'',
          class: '',
        },
        {
          path: '/gestione-linee/turni',
          title: 'Turni',
          icon:'',
          class: '',
        }
      ]
    },
    {
      path: '/avm',
      title: 'avm',
      icon:'fas fa-bus-alt',
      class: '',
      children: [
        {
          path: '/avm/centraline',
          title: 'Centraline',
          icon:'',
          class: '',
        },
        {
          path: '/avm/terminali',
          title: 'Terminali',
          icon:'',
          class: '',
        },
        {
          path: '/avm/mezzi',
          title: 'Mezzi',
          icon:'',
          class: '',
        },
        {
          path: '/avm/flotte',
          title: 'Flotte',
          icon:'',
          class: '',
        }
      ]
    },
    {
      path: '/tariffe-prodotti',
      title: 'Tariffe-Prodotti',
      icon:'pe-7s-cart',
      class: '',
      children: [
        {
          path: '/tariffe-prodotti/prodotti',
          title: 'Prodotti',
          icon:'',
          class: '',
        },
        {
          path: '/tariffe-prodotti/tariffe',
          title: 'Tariffe',
          icon:'',
          class: '',
        },
        {
          path: '/tariffe-prodotti/tipologie-tariffe',
          title: 'Tipologia Utenza Tariffe',
          icon:'',
          class: '',
        },
        {
          path: '/tariffe-prodotti/listini',
          title: 'Listini',
          icon:'',
          class: '',
        }

      ]
    },
    { path: '/users', title: 'Utenti',  icon: 'pe-7s-user', class: '' },
    { path: '/agenzie', title: 'Agenzie',  icon: 'pe-7s-home', class: '' },
    {
      path: '/configurazione',
      title: 'Configurazione',
      icon:'pe-7s-config',
      class: '',
      children: [
        {
          path: '/configurazione/istanze',
          title: 'Configurazione Istanze',
          icon:'',
          class: ''
        }
      ]
    },    /*{ path: '/table', title: 'Table List',  icon:'pe-7s-note2', class: '' },
    { path: '/typography', title: 'Typography',  icon:'pe-7s-news-paper', class: '' },
    { path: '/icons', title: 'Icons',  icon:'pe-7s-science', class: '' },
    { path: '/maps', title: 'Maps',  icon:'pe-7s-map-marker', class: '' },
    { path: '/notifications', title: 'Notifications',  icon:'pe-7s-bell', class: '' },*/
  ];

  constructor(private ms: MasterService) {

  }

  ngOnInit() {
    this.ms.apiGet(this.ms.vars.user.token + '/users/available-routes').subscribe(data => {
      this.ms.vars.routes = data;
      for (let j in this.routes) {
        let r = this.routes[j];
        if (typeof r.children != 'undefined' && r.children.length > 0) {
          for (let x in r.children) {
            let elimina2 = true;
            let c = r.children[x];
            if (typeof c.path != 'undefined') {
              for (let l of this.ms.vars.routes) {
                if (c.path == l.path) {
                  elimina2 = false;
                }
              }

              if (elimina2) {
                delete this.routes[j].children[x];
                continue;
              }
            }
          }
        }
      }

      for (let i in this.routes) {
        let r = this.routes[i];
        let elimina = true;
        for (let l of this.ms.vars.routes) {
          if (r.path == l.path) {
            elimina = false;
          }
        }

        if (elimina) {
          delete this.routes[i];
        }
      }

      for(let idx in this.routes) {
        if (this.routes[idx].children && this.routes[idx].children.length > 0) {
          this.routes[idx].children = this.routes[idx].children.filter(function (el) {
            return el != null;
          });
        }
      }

      this.menuItems = this.routes.filter(function (el) {
        return el != null;
      });
      //console.log(this.menuItems)
    });
  }


  getRoutes(){
    return this.routes;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}
