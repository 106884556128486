import { Component, OnInit } from '@angular/core';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { Router,NavigationEnd } from '@angular/router';
import { MasterService } from './master.service';
declare var $:any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  constructor(public location: Location, private router: Router, private ms: MasterService) {


    /* Verifica routes abilitate */
    if (this.ms.vars.user.token) {
      if (this.ms.vars.routes.length == 0) {
        this.ms.apiGet(this.ms.vars.user.token + '/users/available-routes').subscribe(data => {
          this.ms.vars.routes = data;
        });
      }

      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          /* JQuery da eseguire in ogni pagina */
          $('[required]').prev().append('<span class="required_field"> * </span>');
          /*  */
          if (this.ms.vars.routes.length == 0) {
            this.ms.apiGet(this.ms.vars.user.token + '/users/available-routes').subscribe(data => {
              this.ms.vars.routes = data;
            });
          }

          // check route if is available
          let allow_access = false;
          var current_navigation = e;
          this.ms.vars.routes.find((i) => {
            let curl = current_navigation.url;

            let curl_explode = curl.split('/');
            curl_explode[curl_explode.length - 1] = curl_explode[curl_explode.length-1].replace(/\d+/g, "edit");
            //console.log(curl_explode)
            curl = curl_explode.join("/");
            if (curl == i.path || curl == '/') {
              allow_access = true;
            }

            //console.log([curl, i.path])
          });
          if (!allow_access){
            this.router.navigate(['/accesso-negato'])
          }



        }
      });
    }
  }



  ngOnInit(){
  }

  isMap(path){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice( 1 );
    if(path == titlee){
      return false;
    }
    else {
      return true;
    }
  }
}
