import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';


import {MasterService} from '../master.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation : ViewEncapsulation.None

})
export class LoginComponent implements OnInit {

  data: any = {
    username : "",
    password : ""
  };
  errors: any=[];

  constructor(private _authService: AuthService, public ms: MasterService, private _router: Router) { }
  ngOnInit() {

    this.ms.vars.body_class = 'loginbody';
    document.getElementsByTagName('body')[0].classList.add(this.ms.vars.body_class);
    document.getElementsByTagName('body')[0].classList.remove('whitebody');

  }

  login() {
    this.errors = [];
    if (this.data.username == "" || this.data.password == "") {
      this.errors.push('Inserire username e password');
    }
    else{
      this._authService.login(this.data.username, this.data.password).subscribe(data =>{
        if (Object.keys(data).length > 0) {
          this.ms.vars.user = data['user'];
          this.ms.vars.istanza = data['user'].id_istanza;
          this.ms.vars.routes = data['routes_available'];
          this.ms.vars.body_class = '';

          localStorage.setItem('token', data['user'].token);
          localStorage.setItem('user', JSON.stringify(data['user']));

          this._router.navigate(['/dashboard']);
          document.getElementsByTagName('body')[0].classList.remove('loginbody');
          document.getElementsByTagName('body')[0].classList.add('whitebody');
        } else {
          this.errors.push('Accesso non consentito. Verificare i dati inseriti');
          for(let msg of this.errors){
            this.ms.showNotification('top','center',3, msg);
          }
        }
      });
    }

    if (this.errors.length>0){
      for(let msg of this.errors){
        this.ms.showNotification('top','center',3, msg);
      }
    }
  }

}
