import { Component, OnInit, ElementRef } from '@angular/core';
import { SidebarComponent } from '../../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

import { AuthService } from '../../services/auth.service';
import {MasterService} from '../../master.service';

@Component({
  // moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit{
  private listTitles: any[];
  location: Location;
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(location: Location,  private element: ElementRef, private _authService: AuthService, public ms: MasterService, private sbc: SidebarComponent) {
    this.location = location;
    this.sidebarVisible = false;
  }

  ngOnInit(){
    this.listTitles = this.sbc.getRoutes().filter(listTitle => listTitle);
    if (this.ms.vars.routes.length == 0) {
      this.ms.apiGet(this.ms.vars.user.token + '/users/available-routes').subscribe(data => {
        this.ms.vars.routes = data;
        this.checkRoutes(this.ms.vars.routes);
      });
    } else {
      this.checkRoutes(this.ms.vars.routes);
    }

    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
  }

  checkRoutes(routes) {
    this.listTitles.forEach((r, index) => {
      let elimina = true;
      this.ms.vars.routes.forEach((l, index2) => {
        if (r.path == l.path) {
          elimina = false;
        }
      });
      if (elimina) {
        this.listTitles.splice(index, 1);
      }
    });
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function(){
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');

    this.sidebarVisible = true;
  };
  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  };
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  };

  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.split('/').pop();
    for(var item = 0; item < this.listTitles.length; item++){
      if(this.listTitles[item].path === titlee){
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  logout(){
    this._authService.logout();
  }
}
