import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';

import { map, catchError, finalize,tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

 //test
declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  })
};

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  vars: any = {
    'sessionIdUser': null,
    'istanza': 30,
    'body_class': '',
    'domainDev': 'http://bc2.buscontrol.ahlab',
    'domainDev2': 'https://bc2.buscontrol.it',
    'endpoint': '/api/v2/',
    'errors': [],
    'data_wrap': {},
    'site_data': {},
    'user': {},
    'notifiche': [],
    'routes': [],
    'showLoader': false,
    "datatable": {
      "language": {
        search: "",
        searchPlaceholder: "CERCA NEI RISULTATI ",
        lengthMenu: "Mostra _MENU_ righe",
        info: "Visualizzo da_START_ a _END_ di _TOTAL_ elementi",
        infoEmpty: "Nessun risultato da mostrare.",
        infoFiltered: "(filtrado _MAX_ elementos total)",
        infoPostFix: "",
        loadingRecords: "caricamento in corso...",
        zeroRecords: "Nessun risultato da mostrare",
        emptyTable: "Nessun risultato da mostrare",
        paginate: {
          first: "Primo",
          previous: "<",
          next: ">",
          last: "Ultimo"
        },
        aria: {
          sortAscending: ": Ordinamento ASC",
          sortDescending: ": Ordinamento DESC"
        }
      }
    }
  };

  modal: any = {
    title: "zzzz",
    description: "",
    html: "",
    modalID: "modal1",
    buttons:
    {
      button_ok: {
        label: "Ok",
        function: "",
        active: 0,
        class: "btn-primary",
      },
      button_cancel: {
        label: "Chiudi",
        active: 1,
        class: "btn-default",
      }
    }
  }


  constructor(private http: HttpClient, private location: Location) {
    this.vars.endpoint = this.vars.domainDev2 + this.vars.endpoint;
    //console.log(this.vars)
    if (localStorage.getItem('token')) {
      this.vars.user = JSON.parse(localStorage.getItem('user'));
      this.vars.istanza = this.vars.user.id_istanza
      this.vars.user.token = localStorage.getItem('token');
      this.vars.sessionIdUser = this.vars.user.id;

    }
  }

  convertiParams(obj) {
    var par = [];
    for (var key in obj) {
      if (this.isObject(obj[key])) {
        par.push(key + '=' + JSON.stringify(obj[key]));
      } else {
        if (obj[key]!='null' && obj[key]!=null)
        par.push(key + '=' + encodeURIComponent(obj[key]));
      }
    }
    return par.join('&');
  }

  isObject(val) {
    return (typeof val === 'object');
  }


  cleanSession() {
    this.vars.sessionIdUser = null;
    this.vars.istanza = null;
    this.vars.data_wrap = {};
    this.vars.site_data = {};
    this.vars.user = {};
  }

  goBack() {
    this.location.back();
  }

  apiGet(func, sloader = false) {
    if (sloader)
    this.vars.showLoader = true;

    return this.http.get(this.vars.endpoint + func+'?ii='+this.vars.istanza).pipe(
      finalize(() => {
        setTimeout(() => this.vars.showLoader = false, 950);
      })
    );
  }

  apiPost(func, data, sloader = false) {
    if (sloader)
    this.vars.showLoader = true;
    return this.http.post(this.vars.endpoint + func+'?ii='+this.vars.istanza, this.convertiParams(data), httpOptions).pipe(
      tap(result => {
        $.each(result, function(key, value){
          if (value === "null" || value === null){
            result[key] = "";
          }
        });
        return result;
      }),
      finalize(() => {
        setTimeout(() => this.vars.showLoader = false, 950);
      })
    );
  }

  showNotification(from, align, index, msg, icon = "fas fa-exclamation-triangle fs10") {
    const type = ['', 'info', 'success', 'warning', 'danger'];
    $.notify({
      icon: icon,
      message: msg
    }, {
      type: type[index],
      timer: 1000,
      placement: {
        from: from,
        align: align
      }
    });
  }

  formatDate(date) {
    var d;
    if (typeof date === 'object'){
      d = date
    } else {
      d = new Date(date);
    }

    var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }


  onlyNum(event: any) {
    const pattern = /[0-9\.]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  inArrayByProperty(arr, prop, search) {
    return arr.filter(x => x[prop] == search);
  }



}
